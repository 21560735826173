import type { Interaction, InteractionType, Platforms } from './types.ts';

export const panelBtnEnum = {
  content: 'Content',
  design: 'Design',
  themes: 'Themes'
} as const;

export const PLATFORMS: Record<string, Platforms> = {
  ipa: 'In-person',
  hybrid: 'Hybrid',
  zoom: 'Zoom',
  meet: 'Google Meet',
  teams: 'Microsoft Teams',
  youtube: 'YouTube',
  twitch: 'Twitch',
  vimeo: 'Vimeo',
  webex: 'Webex',
  other: 'Other online platforms',
  linkedin: 'LinkedIn',
  restream: 'Restream',
  streamyard: 'Streamyard',
  airmeet: 'Airmeet',
  kick: 'Kick'
};

export const AUTHORIZATION_PLATFORMS = [
  PLATFORMS.youtube,
  PLATFORMS.twitch,
  PLATFORMS.teams
];

export const interactionEnum: {
  [key in InteractionType]: InteractionType;
} = {
  pollWithOptions: 'pollWithOptions',
  freeForm: 'freeForm', // poll without options
  map: 'map',
  wordCloud: 'wordCloud',
  newWheel: 'newWheel', // winning wheel
  emojisEverywhere: 'emojisEverywhere',
  talkingTiles: 'talkingTiles',
  transientThought: 'transientThought',
  wheel: 'wheel', // choice circle
  'Quick Questions': 'Quick Questions',
  linkLibrary: 'linkLibrary',
  textTrack: 'textTrack',
  'PDF Slide': 'PDF Slide',
  ratingPolls: 'ratingPolls',
  fantasticFans: 'fantasticFans',
  multipleChoice: 'multipleChoice'
};

export const defaultColor: Interaction['color'] = {
  pollHeadingColor: '#FFFFFF',
  pollWithOptionsHeadingColor: '#FFFFFF',
  wcHeadingColor: '#FFFFFF',
  mapHeadingColor: '#FFFFFF',
  ratingPollsHeadingColor: '#FFFFFF',
  winningWheelHeadingColor: '#FFFFFF',
  choiceCircleHeadingColor: '#FFFFFF',
  quickQuestionHeadingColor: '#FFFFFF',
  linkLibraryHeadingColor: '#FFFFFF',
  talkingTilesHeadingColor: '#FFFFFF',
  textTrackHeadingColor: '#FFFFFF',
  emojisEverywhereHeadingColor: '#FFFFFF',
  ttHeadingColor: '#FFFFFF',
  ffHeadingColor: '#FFFFFF',
  mapBgColor: '#000000',
  mapLandColor: '#FFFFFF',
  mapWaterColor: '#B7CAE6',
  bigCircleColor: '#FF0064',
  midCircleColor: '#FF0064',
  smallCircleColor: '#FF0064',
  latestPointColor: '#9567F9',
  individualPointColor: '#FF0064',
  emojisEverywhereBgColor: '#111111',
  pollBgColor: '#111111',
  pollChartColor: '#F97B5F',
  pollLabelColor: '#FFFFFF',
  pollWithOptionsBgColor: '#111111',
  pollWithOptionsChartColor: '#F97B5F',
  pollWithOptionsLabelColor: '#FFFFFF',
  ratingPollsBgColor: '#111111',
  ratingPollsLabelColor: '#FFFFFF',
  ratingPollsChartColor: '#F97B5F',
  talkingTileBgColor1: '#6101DC',
  talkingTileBgColor2: '#F501DD',
  talkingTileBgColor3: '#0501DC',
  talkingTilesBgColor: '#111111',
  talkingTilesTextColor: '#111111',
  textTrackBgColor: '#111111',
  textTrackMultipleColors: true,
  textTrackWordColor: '#319795',
  chatBubble1: '#8942DF',
  chatBubble2: '#56CB77',
  ttTextColor1: '#111111',
  ttTextColor2: '#111111',
  ttBgColor: '#111111',
  ttmultipleColors: false,
  winningWheelBgColor: '#111111',
  choiceCircleBgColor: '#111111',
  linkLibraryBgColor: '#111111',
  quickQuestionBgColor: '#111111',
  wcBgColor: '#111111',
  wcWordColor: '#319795',
  multipleColors: false,
  ffBgColor: '#111111',
  ffTextColor: '#FFFFFF',
  coBgColor: '#F5F5F5',
  coCommentColor: '#000000',
  coUsernameColor: '#000000',
  linkLibraryTextColor: '#FFFFFF',
  quickQuestionTextColor: '#FFFFFF',
  multipleChoiceBgColor: '#111111',
  multipleChoiceChartColor: '#F97B5F',
  multipleChoiceLabelColor: '#FFFFFF',
  multipleChoiceHeadingColor: '#FFFFFF'
};

export const defaultInteractionConfig: Interaction = {
  title: '',
  type: 'pollWithOptions',
  color: { ...defaultColor },
  options: [],
  setting: {
    DefaultView: 'world',
    transientThoughtShowUsername: true,
    LinkType: 'all',
    transientThoughtDisplayStyle: 'cloud',
    CombineWords: false,
    CommentsOverlay: true,
    DisplayStyle: 'percentage',
    FontStyle: 'Sans-Serif',
    oneUoneV: false,
    OptionsList: 'number',
    ProfanityFilter: true,
    showLatestCommentsFirst: false,
    showUserName: true,
    SplitWords: false,
    ttPlaybackSpeed: 'default',
    Vertical: true,
    wheelShowUser: '1',
    prefix: '',
    points: '',
    showResponses: true,
  }
};

export const PRESENT_TIMER_SECS = 10;

export const imageSrcMap: Record<string, string> = {
  Standard: '/images/map-images/Default-5color-1920x1080.png',
  Light: '/images/map-images/Light-4color-1920x1080.png',
  Dark: '/images/map-images/Dark-4color-1920x1080.png',
  Sky: '/images/map-images/Sky-4color-1920x1080.png',
  Globe: '/images/map-images/Default-Globe-10color-1920x1080.png',
  Comic: '/images/map-images/Comic-4color-small.png',
  Pencil: '/images/map-images/Pencil-8indexed-1920x1080.png',
  Blueprint: '/images/map-images/Blueprint-4color-1920x1080.png',
  Bright: '/images/map-images/Bright-4color-small.png',
  'Bright (Globe)': '/images/map-images/Bright-Globe-4color-small.png',
};

export const indexToSettingMap: Record<string, Record<number, string>> = {
  Standard: {
    0: 'mapWaterColor',
    1: 'mapLandColor',
    2: 'mapLandColorLight',
    5: 'individualPointColor',
    6: 'latestPointColor',
  },
  Globe: {
    6: 'mapLandColor',
    8: 'mapWaterColor',
    11: 'individualPointColor',
    12: 'latestPointColor'
  },
  'Bright (Globe)': {
    1: 'mapWaterColor',
    2: 'mapWaterColorLight',
    3: 'mapLandColorLight',
    4: 'mapLandColor',
    5: 'individualPointColor',
    6: 'latestPointColor'
  },
  Bright: {
    0: 'mapLandColor',
    1: 'mapWaterColorLight',
    2: 'mapLandColorLight',
    3: 'mapWaterColor',
    4: 'individualPointColor',
    5: 'latestPointColor',
  },
  Comic: {
    0: 'mapWaterColor',
    2: 'mapLandColorDark',
    3: 'mapLandColor',
    4: 'individualPointColor',
    5: 'latestPointColor',
  },
  Light: {
    4: 'individualPointColor',
    5: 'latestPointColor',
  },
  Dark: {
    4: 'individualPointColor',
    5: 'latestPointColor',
  },
  Sky: {
    4: 'individualPointColor',
    5: 'latestPointColor',
  },
  Pencil: {
    8: 'individualPointColor',
    9: 'latestPointColor',
  },
  Blueprint: {
    4: 'individualPointColor',
    5: 'latestPointColor',
  },
};
