import React, { useMemo } from 'react';
import useFetchUserDetails from './useFetchUserDetails';
import {
  type InteractionType,
  type InteractionSettings,
  type InteractionColors
} from '../types';
import { defaultInteractionConfig } from '../constants';
import useFetchThemes from '@components/themes/hooks/useFetchThemes';

export default function useGlobalSettings(selectedThemeId: string) {
  const defaultSettings =
    defaultInteractionConfig.setting as Required<InteractionSettings>;
  const { data } = useFetchUserDetails();

  const { userThemes = [], defaultThemes = [], defaultThemeId, darkThemeId } = useFetchThemes();

  const themeId = selectedThemeId || defaultThemeId || darkThemeId;

  const allThemes = userThemes.concat(defaultThemes);
  const selectedTheme = useMemo(() => allThemes.find(theme => theme.id === themeId), [themeId]);

  const getGlobalSettings = (
    interactionType: InteractionType
  ): InteractionSettings => {
    if (!data?.status || !selectedTheme) return { ...defaultSettings };
    const settings = data.entity.settings;

    let CombineWords = defaultSettings.CombineWords;
    let CommentsOverlay = defaultSettings.CommentsOverlay;
    let ProfanityFilter = defaultSettings.ProfanityFilter;
    let SplitWords = defaultSettings.SplitWords;
    let Vertical = defaultSettings.Vertical;
    let oneUoneV = defaultSettings.oneUoneV;
    let showLatestCommentsFirst = defaultSettings.showLatestCommentsFirst;
    let showUserName = defaultSettings.showUserName;
    let ttPlaybackSpeed = defaultSettings.ttPlaybackSpeed;
    let DefaultView = defaultSettings.DefaultView;
    let transientThoughtDisplayStyle =
      defaultSettings.transientThoughtDisplayStyle;
    let transientThoughtShowUsername =
      defaultSettings.transientThoughtShowUsername;
    let LinkType = defaultSettings.LinkType;
    let DisplayStyle = defaultSettings.DisplayStyle;
    let FontStyle = defaultSettings.FontStyle;
    let OptionsList = defaultSettings.OptionsList;
    let wheelShowUser = defaultSettings.wheelShowUser;
    let textTrackSplitWords = defaultSettings.textTrackSplitWords;
    let BgImage = selectedTheme.config.default.imageSrc;
    let BgImageOpacity = selectedTheme.config.default.imageOpacity;
    let HeadingFont = selectedTheme.config.default.headingFontFamily;
    let HeadingColor = selectedTheme.config.default.headingColor;
    let prefix = defaultSettings.prefix;
    let points = defaultSettings.points;
    let showResponses = defaultSettings.showResponses;
    let optionOrderType = defaultSettings.optionOrderType;
    let category = defaultSettings.category;
    let mapConfig = selectedTheme.config.settings?.mapConfig;

    switch (interactionType) {
      case 'pollWithOptions': {
        ProfanityFilter = settings.pollWithOptionsProfanityFilter;
        oneUoneV = settings.pollWithOptionsOneUserOneVote;
        CombineWords = settings.pollWithOptionsCombineWords;
        CommentsOverlay = settings.pollWithOptionsShowOverlay;
        OptionsList = settings.pollWithOptionsList;
        DisplayStyle = settings.pollWithOptionsDisplayStyle;
        FontStyle = selectedTheme.config.textFontFamily.pollWithOptionsFontStyle;
        BgImage = selectedTheme.config.images.pollWithOptionsImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.pollWithOptionsImageSrc
          ? selectedTheme.config.images.pollWithOptionsImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.pollWithOptionsHeadingFont;
        HeadingColor = selectedTheme.config.colors.pollWithOptionsHeadingColor;
        break;
      }
      case 'freeForm': {
        ProfanityFilter = settings.pollProfanityFilter;
        oneUoneV = settings.pollOneUserOneVote;
        CombineWords = settings.pollCombineWords;
        CommentsOverlay = settings.pollShowOverlay;
        DisplayStyle = settings.pollDisplayStyle;
        FontStyle = selectedTheme.config.textFontFamily.pollFontStyle;
        BgImage = selectedTheme.config.images.pollImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.pollImageSrc
          ? selectedTheme.config.images.pollImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.pollHeadingFont;
        HeadingColor = selectedTheme.config.colors.pollHeadingColor;
        break;
      }
      case 'map': {
        oneUoneV = settings.mapOneUserOneVote;
        CommentsOverlay = settings.mapShowOverlay;
        DefaultView = settings.mapDefaultView;
        BgImage = selectedTheme.config.images.mapImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.mapImageSrc
          ? selectedTheme.config.images.mapImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.mapHeadingFont;
        HeadingColor = selectedTheme.config.colors.mapHeadingColor;
        break;
      }
      case 'wordCloud': {
        ProfanityFilter = settings.wordCloudProfanityFilter;
        oneUoneV = settings.wordCloudOneUserOneVote;
        CombineWords = settings.wordCloudCombineWords;
        SplitWords = settings.wordCloudSplitWords;
        Vertical = settings.wordCloudVertical;
        CommentsOverlay = settings.wordCloudShowOverlay;
        FontStyle = selectedTheme.config.textFontFamily.wordCloudFontStyle;
        BgImage = selectedTheme.config.images.wcImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.wcImageSrc
          ? selectedTheme.config.images.wcImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.wordCloudHeadingFont;
        HeadingColor = selectedTheme.config.colors.wcHeadingColor;
        break;
      }
      case 'emojisEverywhere': {
        oneUoneV = settings.emojisEverywhereOneUserOneVote;
        CommentsOverlay = settings.emojisEverywhereShowOverlay;
        BgImage = selectedTheme.config.images.emojisEverywhereImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.emojisEverywhereImageSrc
          ? selectedTheme.config.images.emojisEverywhereImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.emojisEverywhereHeadingFont;
        HeadingColor = selectedTheme.config.colors.emojisEverywhereHeadingColor;
        break;
      }
      case 'transientThought': {
        ProfanityFilter = settings.transientThoughtProfanityFilter;
        oneUoneV = settings.transientThoughtOneUserOneVote;
        ttPlaybackSpeed = settings.transientThoughtPlaybackSpeed;
        transientThoughtDisplayStyle = settings.transientThoughtDisplayStyle;
        transientThoughtShowUsername = settings.transientThoughtShowUsername;
        FontStyle = selectedTheme.config.textFontFamily.transientThoughtFontStyle;
        BgImage = selectedTheme.config.images.ttImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.ttImageSrc
          ? selectedTheme.config.images.ttImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.transientThoughtHeadingFont;
        HeadingColor = selectedTheme.config.colors.ttHeadingColor;
        break;
      }
      case 'newWheel': {
        wheelShowUser = settings.wheelShowUser;
        BgImage = selectedTheme.config.images.winningWheelImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.winningWheelImageSrc
          ? selectedTheme.config.images.winningWheelImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.newWheelHeadingFont;
        HeadingColor = selectedTheme.config.colors.winningWheelHeadingColor;
        break;
      }
      case 'Quick Questions': {
        showUserName = settings.quickQuestionShowUserName;
        BgImage = selectedTheme.config.images.quickQuestionImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.quickQuestionImageSrc
          ? selectedTheme.config.images.quickQuestionImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.quickQuestionsHeadingFont;
        HeadingColor = selectedTheme.config.colors.quickQuestionHeadingColor;
        break;
      }
      case 'textTrack': {
        ProfanityFilter = settings.textTrackProfanityFilter;
        SplitWords = settings.textTrackSplitWords;
        Vertical = settings.textTrackVertical;
        FontStyle = selectedTheme.config.textFontFamily.textTrackFontStyle;
        textTrackSplitWords = settings.textTrackSplitWords;
        BgImage = selectedTheme.config.images.textTrackImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.textTrackImageSrc
          ? selectedTheme.config.images.textTrackImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.textTrackHeadingFont;
        HeadingColor = selectedTheme.config.colors.textTrackHeadingColor;
        break;
      }
      case 'fantasticFans': {
        FontStyle = selectedTheme.config.textFontFamily.fantasticFansFontStyle;
        BgImage = selectedTheme.config.images.ffImageSrc || BgImage;
        BgImageOpacity = selectedTheme.config.images.ffImageSrc
          ? selectedTheme.config.images.ffImageOpacity
          : BgImageOpacity;
        HeadingFont = selectedTheme.config.headingFontFamily.fantasticFansHeadingFont;
        HeadingColor = selectedTheme.config.colors.ffHeadingColor;
        break;
      }
      case 'multipleChoice' : {
        prefix = settings.prefix || '';
        points = settings.points || 1;
        showResponses = settings.showResponses || true;
        optionOrderType = settings.optionOrderType || 'number';
        category = settings.category || '';
        break;
      }
      default:
        break;
    }

    return {
      ...selectedTheme.config.images,
      CombineWords,
      CommentsOverlay,
      FontStyle,
      HeadingFont,
      HeadingColor,
      ProfanityFilter,
      SplitWords,
      Vertical,
      oneUoneV,
      showLatestCommentsFirst,
      showUserName,
      ttPlaybackSpeed,
      DefaultView,
      transientThoughtDisplayStyle,
      transientThoughtShowUsername,
      LinkType,
      DisplayStyle,
      OptionsList,
      wheelShowUser,
      textTrackSplitWords,
      prefix,
      points,
      showResponses,
      optionOrderType,
      category,
      enableConfetti: settings.enableConfetti || true,
      mapConfig
    };
  };
  const getGlobalColors = (
    interactionType: InteractionType
  ): InteractionColors => {
    if (!selectedTheme) return defaultInteractionConfig.color;
    let pollBgColor = selectedTheme.config.colors.pollBgColor;
    let pollChartColor = selectedTheme.config.colors.pollChartColor;
    let multipleColors = selectedTheme.config.colors.multipleColors;
    let ttmultipleColors = selectedTheme.config.colors.ttmultipleColors;
    switch (interactionType) {
      case 'pollWithOptions': {
        pollChartColor = selectedTheme.config.colors.pollWithOptionsChartColor;
        pollBgColor = selectedTheme.config.colors.pollWithOptionsBgColor;
        break;
      }
      case 'transientThought': {
        multipleColors = selectedTheme.config.colors.ttmultipleColors;
        break;
      }
      default:
        break;
    }

    return {
      ...defaultInteractionConfig.color,
      ...selectedTheme.config.colors,
      pollBgColor,
      pollChartColor,
      multipleColors,
      textTrackMultipleColors: true,
      ttmultipleColors,
      quickQuestionTextColor: selectedTheme.config.colors.quickQuestionTextColor || '#FFFFFF',
      linkLibraryTextColor: selectedTheme.config.colors.linkLibraryTextColor || '#FFFFFF',
      talkingTilesTextColor: selectedTheme.config.colors.talkingTilesTextColor || '#111111',
    };
  };
  return {
    getGlobalSettings,
    getGlobalColors,
    selectedTheme
  };
}
